$(function() {
  if ($('#resourceRecords').length) {
    $('.resource-date-range').each((index, element) => {
      $(element).daterangepicker({
        autoUpdateInput: false
      });

      $(element).on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      });

      $(element).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
      });
    });

    let container = $("#resourceRecords");

    container.find('li').each((index, element) => {
      let link = $(element).find('a');

      if (link.hasClass('active')) {
        $(link.attr('href')).show();
      }
    });

    container.find('li').on('click', function(event) {
      event.preventDefault();

      let element = $(this);
      let parentUl = element.parent();
      let link = element.find('a');
      let idElementTab = link.attr('href');

      parentUl.find('li a').removeClass('active')
      link.addClass('active');

      container.find('.tab').hide();
      $(idElementTab).show();
    });

    $('.delete-resource-record').each((index, element) => {
      $(element).on('click', (event) => {
        event.preventDefault();
        let confirmed = window.confirm('Do you want to delete?');
        if (confirmed) {
          $.ajax({
            url: element.dataset.url,
            type: "DELETE",
            success: () => { location.reload(); }
          });
        }
      })
    });

    var currentRecordEndpoint;
    var currentRecordType;
    $('#resource-record-modal').on('show.bs.modal', (e) => {
      let resourceId = $('#recordDeploymentResourceId').val();
      let recordId = e.relatedTarget.dataset.resourceRecordId;
      currentRecordEndpoint = `/deployment_resources/${resourceId}/deployment_resource_records/${recordId}`;

      $.ajax({
        url: currentRecordEndpoint,
        type: "GET",
        dataType: "json"
      }).then((response) => {
        $('#recordDateWrapper').hide();
        $('#recordDateRangeWrapper').hide();
        currentRecordType = response.type;

        if (response.type == 'Fly') {
          $('#recordDateWrapper').show();
          $('#recordDate').val(response.date_to);
        }

        if (response.type == 'Drive' || response.type == 'Hotel') {
          $('#recordDateRangeWrapper').show();
          $('#recordDateRange').val(response.date_range);
        }

        $('#recordDetails').val(response.details);
      });
    });

    $('#updateResourceRecordButton').on('click', () => {
      let data = {
        details: $('#recordDetails').val()
      }

      if (currentRecordType == 'Fly') {
        data.date_to = $('#recordDate').val();
      }

      if (currentRecordType == 'Drive' || currentRecordType == 'Hotel') {
        data.date_range = $('#recordDateRange').val();
      }

      $.ajax({
        url: currentRecordEndpoint,
        type: "PATCH",
        data: {
          deployment_resource_record: data
        },
        success: () => { location.reload(); }
      })
    });
  }
});
