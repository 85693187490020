import { DataTable } from "simple-datatables"

$(function() {
  $('#message-modal-form').on('show.bs.modal', function (e) {
    let msgId = e.relatedTarget.dataset.msgId;

    $('#message-modal-form').find('input').each((i, el) => {
      $(el).val(null);
    });

    $('#message-modal-form').find('select').each((i, el) => {
      $(el).val(null);
    });

    $('#message-modal-form').find('textarea').each((i, el) => {
      $(el).val(null);
    });

    if (!msgId) {
      $('#notification-form').attr('action', `/notifications`);
      $('#notification-form-method').val('post');
      $('#msg-user-status').val(5); // User status - active
      $('#msg-status').prop('checked', true); // User status - active
      $('#delete-notification').hide();
      return;
    }

    $.ajax({
      url: `/notifications/${msgId}`,
      type: "GET",
      dataType: "json"
    }).then((res) => {
      $('#msg-id').val(res.id);
      $('#msg-message').val(res.message);
      $('#msg-description').val(res.description);
      $('#msg-user-status').val(res.user_status);
      $('#msg-start-date').val(res.start_date);
      $('#msg-end-date').val(res.end_date);
      $('#msg-status').prop('checked', res.status);

      if (res.user_id) {
        $('#msg-user-id').val(res.user_id);
        $('#msg-user-name').val(res.user_name);
      }

      $('#notification-form').attr('action', `/notifications/${msgId}`);
      $('#notification-form-method').val('patch');
      $('#delete-notification').show();
    });
  });

  $('#save-notification').click(() => {
    let msgId = $('#msg-id').val();
    let msgData = {
      message: $('#msg-message').val(),
      description: $('#msg-description').val(),
      user_status: $('#msg-user-status').val(),
      start_date: $('#msg-start-date').val(),
      end_date: $('#msg-end-date').val(),
      user_id: $('#msg-user-id').val(),
      status: $('#msg-status').prop('checked') ? 1 : 0
    };

    let userName = $('#msg-user-name').val();
    if (!userName) {
      msgData.user_id = null; // Drop user_id if user name is blank
    }

    $('#msg-message').removeClass('is-invalid');

    if (!msgData.message) {
      $('#msg-message').addClass('is-invalid');
      $('.is-invalid').first().focus();
      return;
    }

    if (msgId) {
      $.ajax({
        url: `/notifications/${msgId}`,
        type: "PATCH",
        data: msgData,
        success: () => { location.reload(); }
      });
    } else {
      $.ajax({
        url: `/notifications`,
        type: "POST",
        data: msgData,
        success: () => { location.reload(); }
      });
    }
  });

  $('#delete-notification').click(() => {
    let msgId = $('#msg-id').val();

    if (msgId) {
      $.ajax({
        url: `/notifications/${msgId}`,
        type: "DELETE",
        success: () => { location.reload(); }
      });
    }
  });

  var typingTimer;               //timer identifier
  var doneTypingInterval = 500;  //time in ms (5 seconds)

  $('#msg-user-name').keyup(function(){
    clearTimeout(typingTimer);
    typingTimer = setTimeout(filterPersonnels, doneTypingInterval);
  }).focusout(() => {
    setTimeout(
      () => {
        $('#personnelSuggestions').hide();
      },
      150
    );
  });

  function filterPersonnels() {
    if (!$('#msg-user-name').val()) {
      return; // Exit if blank input
    }

    $.ajax({
      url: "/admin-users/filter-personnels",
      type: "GET",
      dataType: "json",
      data: {
        q: $('#msg-user-name').val()
      }
    }).then((res) => {
      $('#personnelSuggestions').empty();

      res.users.forEach((user) => {
        let optEl = document.createElement('div');
        $(optEl).attr('id', `personnel-${user.id}`);
        $(optEl).addClass('personnel-option');
        $(optEl).data('userId', user.id);
        $(optEl).data('userName', user.name);
        $(optEl).text(user.name);

        $('#personnelSuggestions').append($(optEl));
      });

      $('#personnelSuggestions').show();
    });
  }

  if ($("#notificationsTable").length) {
    let dataTable = new DataTable("#notificationsTable", {
      searchable: false,
      perPage: 25,
      perPageSelect: [10, 25, 50, 100],
      labels: {
        perPage: "",
      }
    });
  }

  $('#personnelSuggestions').on("click", "div.personnel-option" , function() {
    $('#msg-user-name').val(
      $(this).data('userName')
    );

    $('#msg-user-id').val(
      $(this).data('userId')
    );
  });
});
