$(function() {
  $('.delete-deployment-document').each((index, element) => {
    $(element).on('click', (event) => {
      event.preventDefault();
      let confirmed = window.confirm('Do you want to delete?');
      if (confirmed) {
        $.ajax({
          url: element.dataset.url,
          type: "DELETE",
          success: (response) => {
            location = response.redirect;
          }
        });
      }
    })
  });

  var currentDocumentEndpoint;
  $('#deployment-document-modal').on('show.bs.modal', (e) => {
    let deploymentId = $('#documentDeploymentId').val();
    let documentId = e.relatedTarget.dataset.documentId;
    currentDocumentEndpoint = `/deployments/${deploymentId}/deployment_documents/${documentId}`;

    $.ajax({
      url: currentDocumentEndpoint,
      type: "GET",
      dataType: "json"
    }).then((response) => {
      $('#documentDetails').val(response.details);
    });
  });

  $('#updateDocumentButton').on('click', () => {
    let data = {
      details: $('#documentDetails').val()
    }

    $.ajax({
      url: currentDocumentEndpoint,
      type: "PATCH",
      data: {
        deployment_document: data
      },
      success: (response) => {
        location = response.redirect;
      }
    })
  });
});
