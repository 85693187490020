import { DataTable } from "simple-datatables"

function initChoicesFor(element) {
  return new Choices(element, {
    silent: false,
    items: [],
    choices: [],
    renderChoiceLimit: -1,
    maxItemCount: -1,
    addItems: true,
    addItemFilter: null,
    removeItems: true,
    removeItemButton: true,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: true,
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 4,
    searchFields: ['label', 'value'],
    position: 'auto',
    resetScrollPosition: true,
    shouldSort: true,
    shouldSortItems: false,
    placeholder: true,
    placeholderValue: null,
    searchPlaceholderValue: null,
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'auto',
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '',
    addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
      return value1 === value2;
    },
    classNames: {
      containerOuter: 'choices required-certificates',
      containerInner: 'choices__inner',
      input: 'choices__input',
      inputCloned: 'choices__input--cloned',
      list: 'choices__list',
      listItems: 'choices__list--multiple',
      listSingle: 'choices__list--single',
      listDropdown: 'choices__list--dropdown',
      item: 'choices__item',
      itemSelectable: 'choices__item--selectable',
      itemDisabled: 'choices__item--disabled',
      itemChoice: 'choices__item--choice',
      placeholder: 'choices__placeholder',
      group: 'choices__group',
      groupHeading: 'choices__heading',
      button: 'choices__button choices__button-custom fa fa-times',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-choices'
    },
    // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fuse#options
    fuseOptions: {
      include: 'score'
    },
    callbackOnInit: function(){

    },
    callbackOnCreateTemplates: null
  });
}

$(function() {
  const removeAttachedDatePickers = () => {
    $('.addToDeploymentButton').each((index, element) => {
      $(element).data('daterangepicker')?.remove();
    });
  };

  const initDateRangeFor = (selector, options = {}) => {
    let config = {
      autoUpdateInput: false,
      opens: 'left',
      drops: options['drops'] || 'down'
    };

    if (options.start_date) {
      config.minDate = options.start_date;
    }

    if (options.end_date) {
      config.maxDate = options.end_date;
    }

    if (options.initRange) {
      config.startDate = options.initRange.from;
      config.endDate = options.initRange.to;
    }

    if (options.occupied && options.occupied_dates) {
      config.isInvalidDate = (date) => {
        return options.deploymentsDates?.includes(date._d.getTime()) ||
          options.occupied_dates.find((range) => {
            return (Date.parse(range.from) <= date._d) &&
              (Date.parse(range.to) >= date._d)
          });
      }
    }

    $(selector).daterangepicker(config);

    $(selector).on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));

      if (options.apply) {
        options.apply(picker);
      }
    });

    $(selector).on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');

      if (options.cancel) {
        options.cancel(picker);
      }
    });
  };

  var dataTable, vehiclesDataTable, formState, filterLoadCounter, certificatesChoices;
  var filterOnChangeInit = false;
  var vehicleFilterOnChangeInit = false;

  const saveDeploymentPosition = () => {
    $.ajax({
      url: `/deployment_positions/${formState.positionId}`,
      type: "PATCH",
      data: {
        resource_id: formState.resourceId,
        resource_type: formState.resourceType,
        date_range: formState.positionDateRange
      },
      success: (response) => {
        location = response.redirect;
      },
      error: (response) => {
        let errors = response.responseJSON;
        console.log(errors);
      }
    });
  };

  $('#deployment-providers-modal').on('show.bs.modal', (e) => {
    filterLoadCounter = 0;
    formState = {
      positionId: e.relatedTarget.dataset.positionId
    };

    let initRange = {
      from: e.relatedTarget.dataset.from,
      to: e.relatedTarget.dataset.to
    };

    $.ajax({
      url: location.pathname.match(/\/deployments\/[\d]+/)[0],
      data: {
        position_id: formState.positionId
      },
      type: "GET",
      dataType: "json"
    }).then((response) => {
      formState.deployment = response.deployment;
      initDateRangeFor('#providerAvailability', {
        'apply': (picker) => { submitProvidersFilter() },
        'cancel': (picker) => { submitProvidersFilter() },
        initRange,
        ...formState.deployment
      });
      $('#providerAvailability').val(initRange.from + ' - ' + initRange.to);
      let certificatesValues = formState.deployment.required_certificates.map(i => i.toString());
      if (!certificatesChoices) {
        certificatesChoices = initChoicesFor($("#certificates")[0]);
      }
      certificatesChoices.getValue().forEach(i => certificatesChoices.removeActiveItemsByValue(i.value));
      certificatesChoices.setChoiceByValue(certificatesValues);

      submitProvidersFilter();
    });

    dataTable = new DataTable("#deploymentProvidersTable", {
      searchable: false,
      perPage: 10,
      perPageSelect: [10, 25, 50, 100],
      labels: {
        perPage: "",
      },
      columns: [
        { select: 0, hidden: true },
        { select: 6,
          render: function(data, td, rowIndex, cellIndex) {
            let span = document.createElement("span");
            if (data[0].data !== 'null') {
              span.innerHTML = data[0].data;
              span.classList.add('missing-certificate-tag');
            }
            return span.outerHTML;
          }
        },
        {
          select: 7,
          headerClass: 'invisible-header',
          render: function(data, td, rowIndex, cellIndex) {
            let resourceId = data[0].data;
            let button = document.createElement("button");
            button.classList.add('btn', 'btn-primary', 'btn-sm', 'addToDeploymentButton');
            button.innerHTML = 'Add to Deployment';
            button.dataset.resourceId = resourceId;
            button.dataset.resourceType = 'provider';
            button.id = `addToDeploymentButton-${resourceId}`;
            return button.outerHTML;
          }
        }
      ]
    });

    const initApplyResourceButtons = function() {
      $('.addToDeploymentButton').each((index, element) => {
        let deploymentsDates = formState.providers.
          find(item => item.id == element.dataset.resourceId)?.
          deployment_dates?.
          map(date => Date.parse(date));

        initDateRangeFor(`#${element.id}`, {
          'drops': (index < 3) ? 'down' : 'up',
          'apply': (picker) => {
            let data = picker.element[0].dataset;
            formState.resourceId = data.resourceId;
            formState.resourceType = data.resourceType;
            formState.positionDateRange = picker.element.val();
            saveDeploymentPosition();
          },
          occupied: true,
          deploymentsDates,
          ...formState.deployment
        });
      });
    }

    dataTable.on('datatable.refresh', () => {
      initApplyResourceButtons();
    });

    dataTable.on('datatable.page', () => {
      removeAttachedDatePickers();
      initApplyResourceButtons();
    });

    // Providers filter
    var typingTimer;               //timer identifier
    var doneTypingInterval = 500;  //time in ms (5 seconds)

    if (!filterOnChangeInit) {
      $('#providerName').keyup(function(){
        clearTimeout(typingTimer);
        typingTimer = setTimeout(submitProvidersFilter, doneTypingInterval);
      });

      $("#providerLevel").change(function() {
        submitProvidersFilter();
      });

      $("#certificates").change(function() {
        submitProvidersFilter();
      });

      $('#providerStatus').on('change', () => {
        submitProvidersFilter();
      });

      filterOnChangeInit = true;
    }

    function submitProvidersFilter() {
      var options = {
        'position_id': formState.positionId,
        'certificates': $('#certificates').val()
      };

      var providerName = $('#providerName').val();
      options['full_name'] = providerName;

      let providerStatus = $('#providerStatus');
      if (providerStatus.val() != '') {
        options['status'] = {
          'question-name': 'status',
          'answer-value': providerStatus.val()
        }
      }

      let providerLevel = $("#providerLevel");
      if (providerLevel.val() != '') {
        options['provider_level'] = ({
          'question-name': providerLevel.attr('question-name'),
          'question-id': providerLevel.attr('question-id'),
          'answer-value': providerLevel.val(),
          'option-ids': providerLevel.val()
        });
      };

      let providerAvailability = $('#providerAvailability');
      if (providerAvailability.val() != '') {
        var availabilityPicker = providerAvailability.data('daterangepicker');
        var dateFrom = availabilityPicker.startDate.format('YYYY-MM-DD');
        var dateTo = availabilityPicker.endDate.format('YYYY-MM-DD');
        options.availability = {}
        options.availability['date_from'] = dateFrom;
        options.availability['date_to'] = dateTo;
      }

      filterLoadCounter++;
      $.ajax({
        url: "/admin-users/filter-users",
        type: "GET",
        dataType: "json",
        data: {
          options: options
        }
      }).then((response) => {
        filterLoadCounter--;
        let i = dataTable.data.data.length;
        removeAttachedDatePickers();
        while (i--) {
          dataTable.rows.remove(0);
        }
        formState.providers = response.providers;
        if (filterLoadCounter < 1) {
          dataTable.insert(response.providers);
          dataTable.refresh();
        }
      })
    }
  });

  $('#deployment-providers-modal').on('hide.bs.modal', (e) => {
    dataTable.destroy();
  });

  $('#deployment-vehicles-modal').on('show.bs.modal', (e) => {
    formState = {
      positionId: e.relatedTarget.dataset.positionId
    };

    let initRange = {
      from: e.relatedTarget.dataset.from,
      to: e.relatedTarget.dataset.to
    };

    $.ajax({
      url: location.pathname.match(/\/deployments\/[\d]+/)[0],
      data: {
        position_id: formState.positionId
      },
      type: "GET",
      dataType: "json"
    }).then((response) => {
      formState.deployment = response.deployment;

      initDateRangeFor('#vehicleAvailability', {
        'apply': (picker) => { submitVehiclesFilter() },
        'cancel': (picker) => { submitVehiclesFilter() },
        initRange,
        ...formState.deployment
      });
      $('#vehicleAvailability').val(initRange.from + ' - ' + initRange.to);

      submitVehiclesFilter();
    });

    vehiclesDataTable = new DataTable("#deploymentVehiclesTable", {
      searchable: false,
      perPage: 10,
      perPageSelect: [10, 25, 50, 100],
      labels: {
        perPage: "",
      },
      columns: [
        { select: 0, hidden: true },
        {
          select: 6,
          headerClass: 'invisible-header',
          render: function(data, td, rowIndex, cellIndex) {
            let resourceId = data[0].data;
            let button = document.createElement("button");
            button.classList.add('btn', 'btn-primary', 'btn-sm', 'addToDeploymentButton');
            button.innerHTML = 'Add to Deployment';
            button.dataset.resourceId = resourceId;
            button.dataset.resourceType = 'vehicle';
            button.id = `addToDeploymentButton-${resourceId}`;
            return button.outerHTML;
          }
        }
      ]
    });

    const initApplyResourceButtons = function() {
      $('.addToDeploymentButton').each((index, element) => {
        initDateRangeFor(`#${element.id}`, {
          'drops': (index < 3) ? 'down' : 'up',
          'apply': (picker) => {
            let data = picker.element[0].dataset;
            formState.resourceId = data.resourceId;
            formState.resourceType = data.resourceType;
            formState.positionDateRange = picker.element.val();
            saveDeploymentPosition();
          },
          occupied: true,
          ...formState.deployment
        });
      });
    }

    vehiclesDataTable.on('datatable.refresh', () => {
      initApplyResourceButtons();
    });

    vehiclesDataTable.on('datatable.page', () => {
      removeAttachedDatePickers();
      initApplyResourceButtons();
    });

    if (!vehicleFilterOnChangeInit) {
      $('#vehicleType').on('change', () => {
        submitVehiclesFilter();
      });

      vehicleFilterOnChangeInit = true;
    }

    function submitVehiclesFilter() {
      let options = {
        type: $('#vehicleType').val()
      };

      let vehicleAvailability = $('#vehicleAvailability');
      if (vehicleAvailability.val() != '') {
        var availabilityPicker = vehicleAvailability.data('daterangepicker');
        var dateFrom = availabilityPicker.startDate.format('YYYY-MM-DD');
        var dateTo = availabilityPicker.endDate.format('YYYY-MM-DD');
        options.availability = {};
        options.availability['date_from'] = dateFrom;
        options.availability['date_to'] = dateTo;
      }

      $.ajax({
        url: "/vehicles",
        type: "GET",
        dataType: "json",
        data: {
          options: options
        }
      }).then((response) => {
        let i = vehiclesDataTable.data.data.length;
        removeAttachedDatePickers();
        while (i--) {
          vehiclesDataTable.rows.remove(0);
        }
        formState.vehicles = response.vehicles;
        vehiclesDataTable.insert(response.vehicles);
        vehiclesDataTable.refresh();
      });
    }
  });

  $('#deployment-vehicles-modal').on('hide.bs.modal', (e) => {
    removeAttachedDatePickers();
    vehiclesDataTable.destroy();
  });

  if ($("#deploymentsTable").length) {
    let dataTable = new DataTable("#deploymentsTable", {
      searchable: false,
      perPage: 25,
      perPageSelect: [10, 25, 50, 100],
      labels: {
        perPage: "",
      }
    });
  }

  if ($("#deploymentsFormCard").length) {
    $("#deploymentsFormCard").find('.tab').hide();

    let activeTab = $('#deploymentsTabHeader .nav-link.active').attr('href');
    $(activeTab).show();

    $("#deploymentsTabHeader").find('li a').click(() => {
      event.preventDefault();
      $("#deploymentsTabHeader").find('li a').removeClass('active');
      $("#deploymentsFormCard").find('.tab').hide();

      $(event.target).addClass('active');
      let targetTab = $(event.target).attr('href');
      $(targetTab).show();

      // 'Todo' tab table fix
      $('#deploymentTasksTable').dataTable().api().draw();
    });

    initDateRangeFor('#deployment_date_range');

    $('select.cert-opts').each((index, element) => {
      const choices = initChoicesFor(element);

      // if certificates number < 1
      if ($(`#${element.dataset.for}`).val() < 1) {
        choices.containerOuter.element.hidden = true;
      }

      $(`#${element.dataset.for}`).change((el) => {
        if (el.target.value > 0) {
          choices.containerOuter.element.hidden = false;
        } else {
          choices.removeActiveItems();
          choices.containerOuter.element.hidden = true;
        }
      });
    });
  }

  if ($("#deleteDeploymentButton").length) {
    $("#deleteDeploymentButton").on('click', (target) => {
      let id = target.currentTarget.dataset.deploymentId;

      if (id) {
        $.ajax({
          url: `/deployments/${id}`,
          type: "DELETE",
          success: () => { location = '/deployments?scope=draft'; }
        });
      }
    });
  }

  if ($("#copyDeploymentButton").length) {
    $("#copyDeploymentButton").on('click', (target) => {
      let id = target.currentTarget.dataset.deploymentId;

      if (id) {
        $.ajax({
          url: `/deployments/${id}/copy`,
          type: "POST",
          success: (res) => {
            location = `/deployments/${res.id}/edit`;
          }
        });
      }
    });
  }

  if ($("#makeActiveDeploymentButton").length) {
    $("#makeActiveDeploymentButton").on('click', (target) => {
      let id = target.currentTarget.dataset.deploymentId;

      if (id) {
        $.ajax({
          url: `/deployments/${id}/validate`,
          type: "GET",
          success: (res) => {
            const modalTarget = res.valid ?
              '#make-active-deployment-modal' :
              '#invalid-deployment-modal';

            const makeActivemodal = new bootstrap.Modal(modalTarget);
            makeActivemodal.show();
          }
        });
      }
    });
  }
});
