var strftime = require('strftime');

function initChoicesFor(element) {
  return new Choices(element, {
    silent: false,
    items: [],
    choices: [],
    renderChoiceLimit: -1,
    maxItemCount: -1,
    addItems: true,
    addItemFilter: null,
    removeItems: true,
    removeItemButton: true,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: true,
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 4,
    searchFields: ['label', 'value'],
    position: 'auto',
    resetScrollPosition: true,
    shouldSort: true,
    shouldSortItems: false,
    placeholder: true,
    placeholderValue: null,
    searchPlaceholderValue: null,
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'auto',
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '',
    addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
      return value1 === value2;
    },
    classNames: {
      containerOuter: 'choices',
      containerInner: 'choices__inner',
      input: 'choices__input',
      inputCloned: 'choices__input--cloned',
      list: 'choices__list',
      listItems: 'choices__list--multiple',
      listSingle: 'choices__list--single',
      listDropdown: 'choices__list--dropdown',
      item: 'choices__item',
      itemSelectable: 'choices__item--selectable',
      itemDisabled: 'choices__item--disabled',
      itemChoice: 'choices__item--choice',
      placeholder: 'choices__placeholder',
      group: 'choices__group',
      groupHeading: 'choices__heading',
      button: 'choices__button choices__button-custom fa fa-times',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-choices'
    },
    // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fuse#options
    fuseOptions: {
      include: 'score'
    },
    callbackOnInit: function(){

    },
    callbackOnCreateTemplates: null
  });
}

$(function() {
  var table;

  const createTable = (opt) => {
    return $('#byProviderTable').DataTable({
      scrollX: true,
      scrollY: window.innerHeight - 268,
      paging: false,
      info: false,
      searching: false,
      ordering: false,
      fixedColumns: {
        left: 5
      },
      rowGroup: {
        dataSrc: 'name',
        startRender: function ( rows, group ) {
          let url = `/deployments/${rows.data()[0].did}/edit`;
          return `<a href="${url}">${group}</a>`;
        }
      },
      columns: opt.columns,
      data: opt.data,
      columnDefs: [ {
        targets: '_all',
        createdCell: function (td, cellData, rowData, row, col) {
          if (cellData == null) {
            return;
          }

          let tags = cellData.split(';');

          if ( tags[0] == '0' ) {
            $(td).css('background-color', 'lightgray');
            $(td).css('font-weight', 500);
            td.textContent = tags.slice(1).join(', ');
          }
          if ( tags[0] == '1' ) {
            $(td).css('background-color', 'dodgerblue');
            $(td).css('font-weight', 500);
            td.textContent = tags.slice(1).join(', ');
          }
        }
      } ]
    });
  };

  const fixGroupRows = () => {
    $('tr.dtrg-group th').attr('colspan', 5).css('background-color', 'white');
    $('.dtfc-fixed-left').css('z-index', 0);
  };

  const submitFilter = () => {
    let data = {
      name: $('#byProviderDeploymentName').val(),
      status: $('#byProviderDeploymentStatus').val()
    };

    if ($('#byProviderDaterange').val()) {
      data['from'] = $('#byProviderDaterange').data('daterangepicker').startDate.format('YYYY-MM-DD');
      data['to'] = $('#byProviderDaterange').data('daterangepicker').endDate.format('YYYY-MM-DD')
    }

    $.ajax({
      url: '/deployments/by_provider',
      type: "GET",
      data: data,
      dataType: "json",
      success: (response) => {
        table.clear().destroy();

        $('#byProviderTable' + " tbody").empty();
        $('#byProviderTable' + " thead").empty();

        table = createTable(response);
        fixGroupRows();
      }
    });
  }

  if ($('#byProviderDeploymentStatus').length) {
    initChoicesFor($("#byProviderDeploymentStatus")[0]);
    $("#byProviderDeploymentStatus").on('change', function() {
      submitFilter();
    });
  }

  if ($('#byProviderDaterange').length) {
    $('#byProviderDaterange').daterangepicker();

    $('#byProviderDaterange').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      submitFilter();
    });

    $('#byProviderDaterange').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      submitFilter();
    });

    $('#byProviderDaterange').val('');
  }

  if ($('#byProviderDeploymentName').length) {
    var typingTimer;               //timer identifier
    var doneTypingInterval = 500;  //time in ms (5 seconds)

    $('#byProviderDeploymentName').on('keyup', function(){
        clearTimeout(typingTimer);
        typingTimer = setTimeout(submitFilter, doneTypingInterval);
    });
  }

  if ($('#byProviderTable').length) {
    $.ajax({
      url: '/deployments/by_provider',
      type: "GET",
      data: {
        name: $('#byProviderDeploymentName').val()
      },
      dataType: "json",
      success: (response) => {
        table = createTable(response);
        fixGroupRows();
      }
    });
  }
});
