$('document').ready(function() {
  $('.widgets-task-status').click(function() {
    let target = $(this);
    let taskId = target.data('task-id');

    let taskContainer = $(`#task-${taskId}`);
    let taskName = taskContainer.find('h5');
    let taskDescription = taskContainer.find('p');

    taskContainer.toggleClass('completed');
    taskName.toggleClass('text-line-through');
    taskDescription.toggleClass('text-line-through');

    $.ajax({
      url: "/change-task-status",
      type: "GET",
      dataType: "script",
      data: { task_id: taskId }
    });
  })
})
