const handleConfirmOnChange = () => {
    console.log('handleConfirmOnChange');
    const input = $(window.event.target);
    const sectionId = input.attr('section-id');

    if (
        $($(`#section-${sectionId}`).find('.input-1')[0]).
            data('fileinput').
            initialPreviewConfig.
            some(file => file.confirmed)
    ) {
        const modalSelector = `section-confirmation-modal-${sectionId}`;
        const modal = new bootstrap.Modal(document.getElementById(modalSelector));
        $(modal._element).find('button').
            off('click').
            on('click', (event) => {
                saveConfirmationAnswer(input, event.target.value);
            });

        modal.show();
    } else {
        saveConfirmationAnswer(input, '0');
    }
}

const saveConfirmationAnswer = (input, archive) => {
    $.ajax({
        url: "/save_answer",
        type: "GET",
        dataType: "json",
        data: {
            value: input.prop('checked') ? '1' : '0',
            checkbox_value: input.attr('value'),
            value_date: input.val(),
            question_id: input.attr('data-id'),
            block_index: input.attr('block_index'),
            option_id: input.attr('option_id'),
            question_type: input.attr('q-type'),
            user_id: input.attr('user-id'),
            workspace: input.attr('workspace'),
            archive: archive
        },
        success: (res) => {
            if (res.archived) { location.reload(); }

            if (res.pending_validation) {
                $(`#section-pending-label-${res.section_id}`).text('pending validation').show();
            } else {
                $(`#section-pending-label-${res.section_id}`).hide();
            }
        }
    });
}

module.exports = handleConfirmOnChange;
