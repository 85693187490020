// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery3
//= require popper
//= require bootstrap-sprockets
//= require customejsfile
//= require activestorage

//= require jquery
//= require jquery_ujs
//= require intlTelInput
//= require libphonenumber/utils

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require('../js/front')

require('../js/attachments')
require('../js/deployments')
require('../js/deployments_by_provider')
require('../js/deployment_documents')
require('../js/deployment_tasks')
require('../js/notifications_index')
require('../js/provider_filter')
require('../js/resource_additional_info')
require('../js/vehicles')
require('../js/widgets')

window.$ = $;
window.EMS = window.EMS || { version: '3.9.11' }

import intlTelInput from 'intl-tel-input';
window.intlTelInput = window.intlTelInput || intlTelInput;

const handleConfirmOnChange = require('../js/confirmation');
window.handleConfirmOnChange = window.handleConfirmOnChange || handleConfirmOnChange;

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()
