$(function() {
  $('#deployment-task-modal-form').on('show.bs.modal', function (e, b) {
    let taskId = e.relatedTarget.dataset.taskId;

    if (!taskId) {
      $('#deployment-task-user-id').val(EMS.userId);
      $('#deployment-task-user-name').val(EMS.userName);
      return;
    }

    let deploymentId = $('#deployment-id').val();

    $.ajax({
      url: `/deployments/${deploymentId}/tasks/${taskId}`,
      type: "GET",
      dataType: "json"
    }).then((res) => {
      $('#deployment-task-id').val(res.id);
      $('#deployment-task-name').val(res.name);
      $('#deployment-task-description').val(res.description);
      $('#deployment-task-due-date').val(res.due_date);
      $('#deployment-task-reminder-date').val(res.reminder_date);
      $('#deployment-task-status').prop('checked', res.status);

      if (res.user_id) {
        $('#deployment-task-user-id').val(res.user_id);
        $('#deployment-task-user-name').val(res.user_name);
      }
    });
  });

  $('#save-deployment-task').on('click', () => {
    let deploymentId = $('#deployment-id').val();
    let deploymentTaskId = $('#deployment-task-id').val();
    let taskData = {
      name: $('#deployment-task-name').val(),
      description: $('#deployment-task-description').val(),
      due_date: $('#deployment-task-due-date').val(),
      reminder_date: $('#deployment-task-reminder-date').val(),
      user_id: $('#deployment-task-user-id').val(),
      status: $('#deployment-task-status').prop('checked') ? 'completed' : 'not_completed'
    };

    let userName = $('#deployment-task-user-name').val();
    if (!userName) {
      taskData.user_id = null; // Drop user_id if user name is blank
    }

    $('#deployment-task-name').removeClass('is-invalid');

    if (!taskData.name) {
      $('#deployment-task-name').addClass('is-invalid');
      $('.is-invalid').first().trigger('focus');
      return;
    }

    if (deploymentTaskId) {
      $.ajax({
        url: `/deployments/${deploymentId}/tasks/${deploymentTaskId}`,
        type: "PATCH",
        data: taskData,
        success: (response) => {
          location = response.redirect;
        }
      });
    } else {
      $.ajax({
        url: `/deployments/${deploymentId}/tasks`,
        type: "POST",
        data: taskData,
        success: (response) => {
          location = response.redirect;
        }
      });
    }
  });



  var typingTimer;               //timer identifier
  var doneTypingInterval = 500;  //time in ms (5 seconds)

  $('#deployment-task-user-name').on('keyup', function(){
    clearTimeout(typingTimer);
    typingTimer = setTimeout(filterPersonnels, doneTypingInterval);
  }).on('focusout', () => {
    setTimeout(
      () => {
        $('#personnelSuggestions').hide();
      },
      150
    );
  });

  function filterPersonnels() {
    if (!$('#deployment-task-user-name').val()) {
      return; // Exit if blank input
    }

    $.ajax({
      url: "/admin-users/filter-personnels",
      type: "GET",
      dataType: "json",
      data: {
        q: $('#deployment-task-user-name').val()
      }
    }).then((res) => {
      $('#personnelSuggestions').empty();

      res.users.forEach((user) => {
        let optEl = document.createElement('div');
        $(optEl).attr('id', `personnel-${user.id}`);
        $(optEl).addClass('personnel-option');
        $(optEl).data('userId', user.id);
        $(optEl).data('userName', user.name);
        $(optEl).text(user.name);

        $('#personnelSuggestions').append($(optEl));
      });

      $('#personnelSuggestions').show();
    });
  }

  if ($("#deploymentTasksTable").length) {
    let deploymentId = $('#deployment-id').val();

    $.ajax({
      url: `/deployments/${deploymentId}/tasks`,
      type: "GET",
      dataType: "json",
      success: (response) => {
        $("#deploymentTasksTable").DataTable({
          scrollY: window.innerHeight - 488,
          paging: false,
          info: false,
          searching: false,
          ordering: false,
          columns: [
            { data: 'name', title: 'Summary' },
            { data: 'due_date', title: 'Due date' },
            { data: 'reminder_date', title: 'Reminder date' },
            { data: 'status', title: 'Status' },
            { data: 'user', title: 'Personnel' },
            {
              data: 'actions_data',
              title: 'Actions',
              createdCell: function (td, cellData, rowData, row, col) {
                $(td).css('text-align', 'right');
              },
              render: function ( data, type, row, meta ) {
                return `<a href="#" class="edit-deployment-task" data-task-id="${data}">` +
                  '<i class="bi bi-pencil-square ms-2"></i>' +
                  '</a>' +
                  `<a href="#" class="delete-deployment-task" data-task-id="${data}">` +
                  '<i class="bi bi-x bi-2x text-danger"></i>' +
                  '</a>'
              }
            }
          ],
          data: response.data
        });

        $('.delete-deployment-task').each((index, element) => {
          let deploymentId = $('#deployment-id').val();
          let taskId = element.dataset.taskId;
          $(element).on('click', () => {
            $.ajax({
              url: `/deployments/${deploymentId}/tasks/${taskId}`,
              type: "DELETE",
              success: (response) => {
                location = response.redirect;
              }
            });
          });
        });

        $('.edit-deployment-task').each((index, element) => {
          $(element).on('click', () => {
            let modal = new bootstrap.Modal('#deployment-task-modal-form');
            modal.show(element);
          });
        });
      }
    });
  }

  $('#personnelSuggestions').on("click", "div.personnel-option" , function() {
    $('#deployment-task-user-name').val(
      $(this).data('userName')
    );

    $('#deployment-task-user-id').val(
      $(this).data('userId')
    );
  });
});
