import { DataTable } from "simple-datatables"

function initChoicesFor(element) {
  return new Choices(element, {
    silent: false,
    items: [],
    choices: [],
    renderChoiceLimit: -1,
    maxItemCount: -1,
    addItems: true,
    addItemFilter: null,
    removeItems: true,
    removeItemButton: true,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: true,
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 4,
    searchFields: ['label', 'value'],
    position: 'auto',
    resetScrollPosition: true,
    shouldSort: true,
    shouldSortItems: false,
    placeholder: true,
    placeholderValue: null,
    searchPlaceholderValue: null,
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'auto',
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '',
    addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
      return value1 === value2;
    },
    classNames: {
      containerOuter: 'choices',
      containerInner: 'choices__inner',
      input: 'choices__input',
      inputCloned: 'choices__input--cloned',
      list: 'choices__list',
      listItems: 'choices__list--multiple',
      listSingle: 'choices__list--single',
      listDropdown: 'choices__list--dropdown',
      item: 'choices__item',
      itemSelectable: 'choices__item--selectable',
      itemDisabled: 'choices__item--disabled',
      itemChoice: 'choices__item--choice',
      placeholder: 'choices__placeholder',
      group: 'choices__group',
      groupHeading: 'choices__heading',
      button: 'choices__button choices__button-custom fa fa-times',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-choices'
    },
    // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fuse#options
    fuseOptions: {
      include: 'score'
    },
    callbackOnInit: function(){

    },
    callbackOnCreateTemplates: null
  });
}

// $.noConflict();
$(function() {
  var dates = $('input[name="daterange"]').daterangepicker({
    autoUpdateInput: false
  });

  var typingTimer;               //timer identifier
  var doneTypingInterval = 500;  //time in ms (5 seconds)

  $('input[name=provider_name]').keyup(function(){
      clearTimeout(typingTimer);
      typingTimer = setTimeout(submitFilter, doneTypingInterval);
  });

  function submitFilter() {
    var options = {
      'provider_options': $('#providerOptions').val()
    }

    var scope = $('#status-scope').val();
    options['status_scope'] = scope;

    var providerName = $('input[name=provider_name]').val();
    options['full_name'] = providerName

    var availabilityElement = $('.provider-filter-availability');

    if (availabilityElement.val() != '') {
      var availabilityPicker = availabilityElement.data('daterangepicker');
      var dateFrom = '';
      var dateTo = '';
      dateFrom = availabilityPicker.startDate.format('YYYY-MM-DD');
      dateTo = availabilityPicker.endDate.format('YYYY-MM-DD');
      options.availability = {}
      options.availability['date_from'] = dateFrom;
      options.availability['date_to'] = dateTo;
    }

    $('.provider-filter-select').each(function(index){
      let $element = $(this);
      if($element.val() != '' && $element.attr('question-name') != 'availability') {
        options[index] = ({
          'question-name': $element.attr('question-name'),
          'question-id': $element.attr('question-id'),
          'answer-value': $element.val(),
          'option-ids': $element.val()
        });
      }
    })

    $.ajax({
      url: "/admin-users/filter-users",
      type: "GET",
      dataType: "script",
      data: {
        options: options
      }
    }).then(() => {
      let dataTable = new DataTable("#usersTable", {
        searchable: false,
        perPage: 25,
        perPageSelect: [10, 25, 50, 100],
        labels: {
          perPage: "",
        }
      });
    })
  }

  if($('#providers-filter').length) {
    let optionsChoice = initChoicesFor($("#providerOptions")[0]);
    optionsChoice.setChoiceByValue($('#active-option').val());

    $("#providerOptions").on('change', function() {
      submitFilter();
    });
    submitFilter();
  }

  $('.provider-filter-availability').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    submitFilter();
  });

  $('.provider-filter-availability').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
    submitFilter();
  });

  $("select.provider-filter-select" ).change(function() {
    submitFilter();
  });
});
