import { DataTable } from "simple-datatables"

$(function() {
  let vehiclesTable;

  const submitFilter = () => {
    let options = {
      type: $('#assetsType').val()
    };

    let vehicleAvailability = $('#assetsAvailability');
    if (vehicleAvailability.val() != '') {
      var availabilityPicker = vehicleAvailability.data('daterangepicker');
      var dateFrom = availabilityPicker.startDate.format('YYYY-MM-DD');
      var dateTo = availabilityPicker.endDate.format('YYYY-MM-DD');
      options.availability = {};
      options.availability['date_from'] = dateFrom;
      options.availability['date_to'] = dateTo;
    }

    $.ajax({
      url: "/vehicles",
      type: "GET",
      dataType: "json",
      data: {
        options: options
      }
    }).then((response) => {
      let i = vehiclesTable.data.data.length;
      while (i--) {
        vehiclesTable.rows.remove(0);
      }
      vehiclesTable.insert(response.vehicles);
      vehiclesTable.refresh();
    });
  };

  if ($("#vehiclesTable").length) {
    vehiclesTable = new DataTable("#vehiclesTable", {
      searchable: false,
      perPage: 25,
      perPageSelect: [10, 25, 50, 100],
      labels: {
        perPage: "",
      }
    });

    $('#assetsAvailability').daterangepicker();

    $('#assetsAvailability').on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
      submitFilter();
    });

    $('#assetsAvailability').on('cancel.daterangepicker', function(ev, picker) {
      $(this).val('');
      submitFilter();
    });

    $('#assetsAvailability').val('');

    $('#assetsType').on('change', () => {
      submitFilter();
    });

    submitFilter();
  }

  $('#delete-vehicle').click((target) => {
    let id = target.currentTarget.dataset.vehicleId;

    if (id) {
      $.ajax({
        url: `/vehicles/${id}`,
        type: "DELETE",
        success: () => { location = '/vehicles'; }
      });
    }
  });
});
